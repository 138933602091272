// configuration object
window["sap-ui-config"] = {
    "compatVersion": "edge",
    "async": true,
    "supportedLanguages": "en,de,nl",
    "resourceroots": {
        "com.myorg.myapp": "./"
    },
    "onInit": "module:sap/ui/core/ComponentSupport",
    "theme": "sap_fiori_3",
    "libs": "sap.m",
    "preload": "async"
};